<template>
    <div class="certify">
        <div class="btn">
            hover me
            <div class="detail">
                <div class="content">
                    <div class="inner">
                        <p>这是一段测试文字</p>
                        <p>这是一段测试文字</p>
                        <p>这是一段测试文字</p>
                        <p>这是一段测试文字</p>
                        <p>这是一段测试文字</p>
                        <p>这是一段测试文字</p>
                        <p>这是一段测试文字</p>
                        <p>这是一段测试文字</p>                        
                        
                        <p>这是一段测试文字</p>                        
                        <p>这是一段测试文字</p>                        
                        <p>这是一段测试文字</p>                         
                        <p>这是一段测试文字</p>                        
                        <p>这是一段测试文字</p>                        
                        <p>这是一段测试文字</p>                        
                        <p>这是一段测试文字</p>                         
                        <p>这是一段测试文字</p>                        
                        <p>这是一段测试文字</p>                        
                        <p>这是一段测试文字</p>                        
                        <p>这是一段测试文字</p>                        
                        <p>这是一段测试文字</p>                        
                        <p>这是一段测试文字</p>                        
                        <p>这是一段测试文字</p>                        
                        <p>这是一段测试文字</p>                        
                        <p>这是一段测试文字</p>                        
                        <p>这是一段测试文字</p>                        
                        <p>这是一段测试文字</p>                        
                        <p>这是一段测试文字</p>                        
                        <p>这是一段测试文字</p>                        
                        <p>这是一段测试文字</p>                        
                        <p>这是一段测试文字</p>                        
                        <p>这是一段测试文字</p>                        
                        <p>这是一段测试文字</p>                        
                        <p>这是一段测试文字</p>                        
                        <p>这是一段测试文字</p>                        
                        <p>这是一段测试文字</p>                        
                        <p>这是一段测试文字</p>                        
                        <p>这是一段测试文字</p>                        
                        <p>这是一段测试文字</p>                        
                        <p>这是一段测试文字</p>                        
                        <p>这是一段测试文字</p>                        
                        <p>这是一段测试文字</p>                        
                        <p>这是一段测试文字</p>                        
                        <p>这是一段测试文字</p>                        
                    </div>
                </div>
            </div>
        </div>


        <vue-marquee-slider id="marquee-slider" :speed="15000" :space="1200" :width="1200" >
            <p>非常难得又值钱的认证证书</p>
        </vue-marquee-slider>
        <div class="test-swiper-container">
            <div class="swiper-wrapper">
                <div class="swiper-slide">
                    <img src="/static/image/test/certify01.png" />
                    <p>非常难得又值钱的认证证书</p>
                </div>
                <div class="swiper-slide"><img src="/static/image/test/certify02.png" />
                    <p>深圳市优秀互联网企业认定证书</p>
                </div>
                <div class="swiper-slide"><img src="/static/image/test/certify03.png" />
                    <p>质量管理体系认证荣誉证书</p>
                </div>
                <div class="swiper-slide"><img src="/static/image/test/certify04.png" />
                    <p>计算机软件著作权登记证书</p>
                </div>
                <div class="swiper-slide"><img src="/static/image/test/certify05.png" />
                    <p>增值电信业务经营许可证</p>
                </div>
            </div>
        </div>
        <div class="swiper-pagination"></div>
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
    </div>
</template>

<script>

export default {
    name: 'testt',

    mounted() {
        const certifySwiper = new Swiper('.certify .test-swiper-container', {
            watchSlidesProgress: true,
            slidesPerView: 'auto',
            centeredSlides: true,
            loop: true,
            loopedSlides: 5,// ，主要用于 loop: true（循环模式）时，控制在循环模式下前后复制的幻灯片数量。
            autoplay: false,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            pagination: {
                el: '.swiper-pagination',
                //clickable :true,
            },
            on: {
                progress: function (progress) {
                    console.log('slide progress: ', progress, this.slides);
                    
                    for (let i = 0; i < this.slides.length; i++) {
                        var slide = this.slides.eq(i);
                        var slideProgress = this.slides[i].progress;
                        console.log('slideProgress',slideProgress, slide);
                        
                        let modify = 1;
                        if (Math.abs(slideProgress) > 1) {
                            modify = (Math.abs(slideProgress) - 1) * 0.3 + 1;
                        }
                        let translate = slideProgress * modify * 150 + 'px';
                        let scale = 1 - Math.abs(slideProgress) / 5;
                        let zIndex = 999 - Math.abs(Math.round(10 * slideProgress));
                        slide.transform('translateX(' + translate + ') scale(' + scale + ')');
                        slide.css('zIndex', zIndex);
                        slide.css('opacity', 1);
                        if (Math.abs(slideProgress) > 1) {
                            slide.css('opacity', 0);
                        }
                    }
                },
                setTransition: function (transition) {
                    for (var i = 0; i < this.slides.length; i++) {
                        var slide = this.slides.eq(i)
                        slide.transition(transition);
                    }

                }
            }

        })
    }
}
</script>


<style scoped>
.certify {
    position: relative;
    width: 1200px;
    margin: 0 auto
}

.certify .test-swiper-container {
    padding-bottom: 60px;
}

.certify .test-swiper-container {
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
}

.certify .swiper-slide {
    width: 520px;
    height: 408px;
    background: #fff;
    box-shadow: 0 8px 30px #ddd;
}

.certify .swiper-slide img {
    display: block;
}

.certify .swiper-slide p {
    line-height: 98px;
    padding-top: 0;
    text-align: center;
    color: #636363;
    font-size: 1.1em;
    margin: 0;
}

.certify .swiper-pagination {
    width: 100%;
    bottom: 20px;
}

.certify .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 5px;
    border: 3px solid #fff;
    background-color: #d5d5d5;
    width: 10px;
    height: 10px;
    opacity: 1;
}

.certify .swiper-pagination-bullets .swiper-pagination-bullet-active {
    border: 3px solid #00aadc;
    background-color: #fff;
}

.certify .swiper-button-prev {
    left: -30px;
    width: 45px;
    height: 45px;
    background: url(/static/image/test/wm_button_icon.png) no-repeat;
    background-position: 0 0;
    background-size: 100%;
}

.certify .swiper-button-prev:hover {
    background-position: 0 -46px;
    background-size: 100%
}

.certify .swiper-button-next {
    right: -30px;
    width: 45px;
    height: 45px;
    background: url(/static/image/test/wm_button_icon.png) no-repeat;
    background-position: 0 -93px;
    background-size: 100%;
}

.certify .swiper-button-next:hover {
    background-position: 0 -139px;
    background-size: 100%
}

.detail {
    display: grid;
    grid-template-rows: 0fr;
    transition: grid 0.5s;
    overflow: hidden;
}

.detail > .content {
    min-height: 0;
}

.btn:hover .detail {
    
    grid-template-rows: 1fr;
}
</style>