<template>
  <div class="common__pageContainer__3-VhW">
    <div class="common__pageBody__1Dn3a">
      <div class="help__help__cLCx3">
        <div class="help__help_box__2kBjB help__clearfix__eGfiN">
          <!-- 左侧菜单栏 -->
          <div class="help__help_box_left__1rCP8">
            <ul class="help__parentUl__2pNA3">
              <div v-for="(item, index) in articlesList" :key="index">
                <li data-analytics="menu" data-id="1" @click="changTitle(item.title)" 
                  >
                  <div class="help__block__AtyXW" style="display: none;"></div>
                  
                  <span class="help__title__2KI0N" style="display: flex;align-items: center;column-gap: 6px;">
                    <img :src="item.icon" style="width: 20px;height: 20px;"/>
                    <span>{{ item.title  }}</span>
                  </span>
                </li>
              </div>
            </ul>
          </div>
          <!-- 右侧内容区域 -->
          <div class="help__help_box_right__3vJ4w" style="position: relative;">
            <div v-html="current_content"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      type: 1, // 默认选择第一个
      articlesList: [],
      title: null,
      current_content:null
    };
  },
  created() {
    const that = this;
    that.getConfitData()
  },
  methods: {
    getConfitData() {
      let that = this;
      that.configData = that.$parent.getConfigDatas();
      if (!that.configData.exchangeRateInfo) {
        setTimeout(() => {
            that.getConfitData();
        }, 500);
      } else {
        that.articlesList = that.configData.articlesList
        console.log('获取到配置信息为---文章信息', that.articlesList );
        that.title = that.articlesList[0].title;
        that.current_content = that.articlesList[0].content;

      }
    },
    changType(type) {
      this.type = type;
    },
    changTitle(title) {
      const that = this;
      that.title = title;
      that.current_content = that.articlesList.find(item => item.title === title).content;
    }
  },
};
</script>

<style scoped>
/* 样式部分 */
.common__pageContainer__3-VhW {
  /* 页面容器样式 */
}
.help__titleImg__2hQCq img {
  width: 100px;
}
.help__title__2KI0N {
  font-size: 16px;
  color: #333;
}
.help__help__cLCx3 {
    width: 100%;
    background-color: #fff;
    padding-top: 30px;
    height: auto;
}

.help__help__cLCx3 .help__help_box__2kBjB {
    width: 1200px;
    margin: 30px auto 60px;
    margin-top: 0;
    min-height: 616px;
    height: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    border-radius: 10px;
    -webkit-box-shadow: 0 0 12px 0 rgba(0, 0, 0, .05);
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, .05);
    overflow: hidden;
    background-color: #fff;
}
.help__clearfix__eGfiN {
    zoom: 1;
}
.help__clearfix__eGfiN:before, .help__clearfix__eGfiN:after {
    content: "";
    display: table;
}
.help__clearfix__eGfiN:after {
    clear: both;
    overflow: hidden;
}

.help__help__cLCx3 .help__help_box__2kBjB .help__help_box_left__1rCP8 {
    width: 240px;
    float: left;
}

.help__help__cLCx3 .help__help_box__2kBjB .help__help_box_left__1rCP8 .help__parentUl__2pNA3 {
    padding: 20px 0;
}

.help__help__cLCx3 .help__help_box__2kBjB .help__help_box_left__1rCP8 .help__parentUl__2pNA3 li {
    width: 250px;
    height: 50px;
    color: #364a51;
    margin-bottom: 5px;
    line-height: 50px;
    position: relative;
    cursor: pointer;
}


.help__help__cLCx3 .help__help_box__2kBjB .help__help_box_right__3vJ4w {
    padding: 30px;
    width: 100%;
    height: auto;
    overflow-y: auto;
    border-left: solid 1px #efefef;
    float: left;
    line-height: 2em;
}

.help__help__cLCx3 .help__help_box__2kBjB .help__help_box_left__1rCP8 .help__parentUl__2pNA3 li .help__title__2KI0N {
    font-size: 14px;
    font-weight: bold;
    margin-left: 68px;
}
</style>
