import { getToken } from '@/utils/commonUtil';
import axios from 'axios';

// const BASE_URL = import.meta.env.VITE_ROOT_URL;
const instance = axios.create({
    timeout: 60 * 1000,
    headers: {
        'Accept': 'application/json',
        "Content-Type": "application/json"
    }
});

// 添加请求拦截器
instance.interceptors.request.use(function (config) {
    // 往header头自动添加token
    const token = getToken()
    if (token) {
        config.headers["Authorization"] = token
    }
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

instance.interceptors.response.use(function (response) {
    const data = response.data;
    return data;
  }, function (error) {
    console.log('请求失败',error);
    return Promise.reject(error);
 })

export default instance;

