import axios from '@/axios'
import { getHallHost, getResponseData, getRquestData } from '@/utils/commonUtil'

//请求帮助中心数据
export async function getHelpCenterData(data) {
    const host = getHallHost()
    if (host) {
        return axios.post( 'https://gate.cashcat.club/api/client/helpCenter',{
            data: getRquestData('ReqHelpCenterDataMessage', data, 'ReqHelpCenterData'),
            id: 400501
        }, {
            transformResponse: [getResponseData('ResHelpCenterDataMessage')]
        })
    }
    
}

//请求新闻数据
export async function getNewsData(data) {
    const host = getHallHost()
    if (host) {
        return axios.post( 'https://' + host +'/_api/graphql',{
            data: getRquestData('ReqNewsDataMessage', data, 'ReqNewsData'),
            id: 400503
        }, {
            transformResponse: [getResponseData('ResNewsDataMessage')]
        })
    }
    
}

//请求弹窗数据
export async function getPopupData(data) {
    const host = getHallHost()
    if (host) {
        return axios.post( 'https://' + host +'/_api/graphql',{
            data: getRquestData('ReqPopupDataMessage', data, 'ReqPopupData'),
            id: 400511
        }, {
            transformResponse: [getResponseData('ResPopupDataMessage')]
        })
    }
}

//请求配置数据
export async function getConfigData(data) {
    const host = getHallHost()
    if (host) {
        return axios.post( 'https://' + host +'/_api/graphql',{
            data: getRquestData('ReqConfigDataMessage', data, 'ReqConfigData'),
            id: 400501
        }, {
            transformResponse: [getResponseData('ResConfigDataMessage')]
        })
    }
}