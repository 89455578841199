<template>
  <div class="main_container_gameList">
    <div class="content_container">
      <div class="container_text">
        <span class="text_title">{{ showText[paramChannel].title }}</span>
        <span class="text_sub_title">{{
          showText[paramChannel].subTitle
        }}</span>
      </div>
      <div class="gameGrid">
        <div
          @click="$parent.entryThreeGame(item.gameId)"
          v-for="(item, index) in gameList"
          :key="index"
          class="parent text"
          :style="{ width: getWidth }"
        >
          <div class="game_text">
            <span class="game_title">{{ platNames[index] }}</span>
            <div
              style="
                display: flex;
                align-items: center;
              "
            >
              <span class="game_sub_title">{{ numList[index] }}</span>
              <!-- <span>{{ getTypeName }}</span> -->
              <span class="entry_game_text">进入游戏</span>
            </div>
          </div>
          <img :src="item.fileUrl" class="entry_game_img" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "realHuman",
  data() {
    return {
      type: 0,
      gameChnenlList: [],
      paramChannel: this.$route.params.channels,
      gameList: [],
      numList: [],
      platNames: [],
      showText: {
        100: {
          title: "真人娱乐",
          subTitle:
            "真实赌场环境，美艳荷官与您互动。咪牌百家乐，体验包桌尊贵服务，给您亲临现场般的游戏体验。",
        },
        200: {
          title: "体育赛事",
          subTitle:
            "每天提供上千场精彩体育赛事，多种投注玩法任您选择，稳定的赛事直播视频，倾力打造最专业的体育娱乐。",
        },
        400: {
          title: "棋牌游戏",
          subTitle:
            "万人在线，公平公正。抢庄牛牛、龙虎斗等多款棋牌任君选，从天而降的彩金，等您来赢！",
        },
      },
    };
  },
  created() {
    let that = this;
    that.getChannelList();
  },
  computed: {
    getTypeName() {
      const that = this;
      if (that.paramChannel == 100 ) {
        return "桌";
      }
      if (that.paramChannel == 200) {
        
        return "场";
      }
      
      if (that.paramChannel == 400) {
        return "种"
      }
    },
    getWidth() {
      const that = this;
      if (that.paramChannel == 100 || that.paramChannel == 400) {
        return "400px";
      }
      if (that.paramChannel == 200) {
        
        return "300px";
      }
    }
  },
  methods: {
    getChannelList() {
      let that = this;
      that.$parent.showLoading();
      const channelList = that.$parent.getGameChannelList();
      if (channelList.length === 0) {
        setTimeout(() => {
          that.getChannelList();
        }, 500);
      } else {
        const platFormList = channelList.gameChannelsList.filter(
          (item) => item.channels == that.paramChannel
        )[0];
        const realGameList = [];
        platFormList.platformList.forEach((item) => {
          that.numList.push(item.num);
          realGameList.push(item.gameApiInfo[0]);
          that.platNames.push(item.platformName);
        });
        console.log(
          "查询游戏平台列表",
          platFormList,
          realGameList,
          that.numList
        );
        that.gameList = realGameList;
        that.$parent.hideLoading();
      }
    },
    changType(index) {
      let that = this;
      that.type = index;
    },
  },
  mounted() {
    let that = this;
  },
  updated() {
    let that = this;
  },
  beforeDestroy() {},
};
</script>
<style scoped>
.entry_game_img {
  bottom: 0;
  position: absolute;
  will-change: transform;
  height: 300px;
}
.entry_game_text {
  border: 0;
  color: #bd171b;
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
  will-change: transform;
}
.entry_game_text::after {
  content: "^";
  font-family: serif;
  font-size: 27px;
  font-weight: 400;
  left: 64px;
  position: absolute;
  transform: rotate(90deg);
}

.game_text {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  height: 79px;
  margin-left: 40px;
  margin-top: 40px;
  position: relative;
  width: 179px;
  will-change: transform;
  z-index: 10;
}

.game_text > .game_title {
  color: #1e1e20;
  font-family: PingFang SC;
  font-size: 32px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 45px;
  -webkit-user-select: none;
  user-select: none;
  will-change: transform;
}

.game_text .game_sub_title {
  color: #6e6e73;
  font-family: DINPro-bold;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 20px;
  -webkit-user-select: none;
  user-select: none;
  will-change: transform;
}

.gameGrid {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(400px, 1fr)
  );
}

.main_container_gameList {
  background-color: #FFF9ED;
  min-height: 800px;
  width: 100vw;
  display: flex;
  justify-content: center;
  padding: 60px 0;
}

.content_container {
  width: 67.71%;
  row-gap: 60px;
  display: flex;
  flex-direction: column;
}

.container_text {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.text_title {
  color: #1e1e20;
  font-family: PingFang SC;
  font-size: 48px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 67px;
}

.text_sub_title {
  color: #6e6e73;
  font-family: PingFang SC;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 28px;
}

.container_text {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.text_title {
  color: #1e1e20;
  font-family: PingFang SC;
  font-size: 48px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 67px;
}

.text_sub_title {
  color: #6e6e73;
  font-family: PingFang SC;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 28px;
}

.parent {
  background-image: url("/static/image/secondgame_card_bg_lrg.0e3781.webp");
  background-size: contain;
  background-repeat: no-repeat;
  height: 480px;
  transition: transform 0.3s cubic-bezier(0, 0, 0.5, 1);
  cursor: pointer;
  position: relative;
}

.text {
  background-color: initial;
  /* background-image: none; */
  /* background-image: var(--secondgame_card_bg_mdm); */
  background-position: 50%;
  background-repeat: no-repeat;
  /* background-size: cover; */
  border-radius: 20px;
  box-shadow: 0 10px 20px 0 #3578f61a;
  cursor: pointer;
  height: 480px;
  overflow: hidden;
  position: relative;
  width: 400px;
}

.parent:hover {
  transform: scale3d(1.02, 1.02, 1.02);
}
</style>