<template>
  <div class="scrollLazy" style="min-height: 0px;">
    <div class="scrollLazy" style="min-height: 0px;">
      <div class="style__footer__3lDH1" id="ob_footer">
        <div class="style__logbox__1U__O">
          <img @mouseenter="handleHover(index)" @mouseleave="handleLeve(index)" 
          v-for="(item,index) in manu_logo" :key="index" :src="currnet_hover_logo == index ? item.hoverImg : item.img" >
        </div>
        <hr/>
        <div class="style__explanation__18qt1">
          <p>{{ $store.state.appInfo.title }}拥有欧洲马耳他MGA和菲律宾政府（PAGCOR）颁发的合法牌照。 注册于英属维尔京群岛，是受国际协会认可的合法公司。进行注册并娱乐前，请确保您年满18周岁！</p>
        </div>
        <div class="style__logoList__2uccD">
          <div>
            <div></div>
            <span>马耳他牌照(MGA)认证</span>
          </div>
          <div>
            <div></div>
            <span>英属维尔京群岛(BVI)认证</span>
          </div>
          <div>
            <div></div>
            <span>菲律宾(PAGCOR)监督牌照</span>
          </div>
        </div>
        <div class="style__helpbox__2sqb7">
          <div @click="$parent.goNav('/abouts?type='+ item.title)" v-for="(item,index) in articlesList" :key="index" >
              {{ item.title }}
          </div>
        </div>
        <p class="style__copyright__10clK">版权所有 ©2023-2024 {{ $store.state.appInfo.title }} 保留所有权</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Foot',
  data() {
    return {
      articlesList: [],
      currnet_hover_logo: null,
      manu_logo: [
        {
          img: '/static/image/foot/aglogo0.png',
          hoverImg: '/static/image/foot/aglogo.png'
        },
        {
          img: '/static/image/foot/btilogo0.png',
          hoverImg: '/static/image/foot/btilogo.png'
        },
        {
          img: '/static/image/foot/fclogo0.png',
          hoverImg: '/static/image/foot/fclogo.png'
        },
        {
          img: '/static/image/foot/sabalogo0.png',
          hoverImg: '/static/image/foot/sabalogo.png'
        }
      ]
    }
  },
  created() {
    const that = this;
    that.getConfitData();
  },
  methods: {
    handleHover(index) {
      console.log('hover', index);
      const that = this;
      that.currnet_hover_logo = index;
    },
    handleLeve(index) {
      const that = this;
      that.currnet_hover_logo = null;
    },
    getConfitData() {
      let that = this;
      that.configData = that.$parent.getConfigDatas();
      if (!that.configData.exchangeRateInfo) {
        setTimeout(() => {
            that.getConfitData();
        }, 500);
      } else {
        that.articlesList = that.configData.articlesList
        console.log('获取到配置信息为---文章信息', that.articlesList );
      }
    },
    goNav(path) {
      this.$router.push(path);
    }
  }
};
</script>

<style scoped>
/* 在这里添加你的样式 */
</style>
