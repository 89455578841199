import protoRoot from '@/protos/proto';
import protobuf from 'protobufjs';

const TOKENKEY = "token";
export const LANG = 'locale'
export const LEFT_SIDER_WIDTH = "260px"
const HALL_HOST = "hall_host";
const IS_LOGIN = "is_login";

export const webSocketMap = {
  450002: "ResTcpTokenAuthMessage",
  450004: "ResTcpHeartBeatMessage",
  450005: "ResTcpSysErrorMessage",
  450006: "ResTcpCurrencyUpdateMessage",
  450007: "ResTcpReceiveInboxMessage",
  450008: "ResTcpVipClubExpChangeMessage",
  450009: "ResTcpBulletinDataMessage",
  450010: "ResTcpGameNoteDataMessage",
  450011: "ResTcpMyBetDataMessage",
}

export function setToken(token) {
  return localStorage.setItem(TOKENKEY, token);
}

export function getToken() {
  return localStorage.getItem(TOKENKEY);
}

export function clearToken() {
  return localStorage.removeItem(TOKENKEY);
}

export function setHallHost(host) {
  return localStorage.setItem(HALL_HOST, host);
}

export function getHallHost() {
  return localStorage.getItem(HALL_HOST);
}

export function clearHallHost() {
  return localStorage.removeItem(HALL_HOST);
}

export function setIsLogin(login) {
  return localStorage.setItem(IS_LOGIN, login);
}

export function getIsLogin() {
  return localStorage.getItem(IS_LOGIN);
}

export function clearIsLogin() {
  return localStorage.removeItem(IS_LOGIN);
}

// 格式化千分位数字
export function formatNumberQfw(num, minFractd = 2, maxFractd = 10) {
  if (num === null || num === undefined) {
    return ''
  }
  const options = {
    style: 'decimal',
    minimumFractionDigits: minFractd,
    maximumFractionDigits: maxFractd
  };
  return new Intl.NumberFormat('en-US', options).format(num);
}

export function formatDate(date) {
  var day = date.getDate().toString().padStart(2, '0');
  var month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth()返回的是0-11
  var year = date.getFullYear().toString().slice(-2); // 取最后两位年份
  var hours = date.getHours().toString().padStart(2, '0');
  var minutes = date.getMinutes().toString().padStart(2, '0');

  var formattedDate = `${day}/${month}/${year},${hours}:${minutes}`;
  return formattedDate;
}

export function formatDate2(date, type = 1) {
  if (!date) {
    return ''
  }
  var newDate = new Date(date);
  var day = newDate.getDate().toString().padStart(2, '0');
  var month = (newDate.getMonth() + 1).toString().padStart(2, '0'); // getMonth()返回的是0-11
  var year = newDate.getFullYear().toString(); // 取最后两位年份
  var hours = newDate.getHours().toString().padStart(2, '0');
  var minutes = newDate.getMinutes().toString().padStart(2, '0');
  var seconds = newDate.getSeconds().toString().padStart(2, '0');

  if (type === 1) {
    return `${day}/${month}/${year}`;
  } else if (type === 2) {
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
  } else if (type === 3) {
    return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`
  } else if (type === 4) {
    return `${hours}:${minutes}:${seconds}`
  } else if (type === 5) {
    return `${year}-${month}-${day}`
  }
  var formattedDate = `${year}/${month}/${day}`;
  return formattedDate;
}

export function formatDate3(date, haveTime = true) {
  var newDate = new Date(date);
  var options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour12: false
  };
  // 添加时间选项（小时和分钟）如果 haveTime 为 true
  if (haveTime) {
    options.hour = '2-digit';
    options.minute = '2-digit';
  }
  var formattedDate = newDate.toLocaleString('en-US', options);
  return formattedDate;
}

export function getAllTheme() {
  const allThemes = {
    dark: {
      name: 'dark',
      data: darkTheme,
      componentTheme: darkComponentTheme
    },
    light: {
      name: 'light',
      data: lightTheme,
      componentTheme: lightComponentTheme
    }
  }
  return allThemes
}

// reqDesction: 前端请求的接口名称，如：'ReqLogin'
// data: 前端请求的参数，如：{account: 'admin', password: '123456'}
// midCode: 前端请求的MID，如：'ReqLogin'
export function getRquestData(reqDesction, data, midCode, isWebSocket = false) {
  if (!isWebSocket && window.location.href) {
    // 创建URL对象
    const parsedUrl = new URL(window.location.href);
    // 获取hostname（这将是IP地址或域名）
    const hostname = parsedUrl.hostname;
    // 获取查询参数
    const searchParams = parsedUrl.searchParams;
    // 将查询参数转换为字符串形式
    let paramsString = '';
    searchParams.forEach((value, key) => {
      if (paramsString !== '') {
        paramsString += '&';
      }
      paramsString += `${key}=${value}`;
    });
    data.host = hostname;
  }

  // console.log(typeof reqDesction);
  // console.log(reqDesction);
  // const mid = protoRoot.lookup('MID')
  const mid = protoRoot.ProtoMessage.MID
  console.log("请求接口===>:", data, typeof midCode, typeof midCode === 'number'? midCode : mid[midCode], formatDate2(new Date(), 2));

  if (!mid) {
    throw new Error('mid not found')
  }
  const msgId = typeof midCode === 'number'? midCode : mid[midCode]
  if (!msgId) {
    throw new Error('msgId not found')
  }
  // const reqDesctionData = protoRoot.lookup(reqDesction); // 根据前端传入的reqDesction找到接口需要的参数
  let reqDesctionData
  if (typeof reqDesction === 'string') {
    reqDesctionData = protoRoot.ProtoMessage[reqDesction]
  } else {
    reqDesctionData = reqDesction
  }
  if (!reqDesctionData) {
    throw new Error('reqDesctionData not found')
  }
  const err = reqDesctionData.verify(data) // 检验传入的参数格式是否正确
  if (err) {
    throw new Error(err) // 如果格式不正确，则抛出错误
  }

  const message = reqDesctionData.create(data) // 创建成消息的格式
  const reqData = reqDesctionData.encode(message).finish() // 解析成一个二进制流
  // 新增的代码...
  const bytes = new Uint8Array(reqData);
  const msgDataLength = 4 + bytes.length;
  const totalBuffer = new Uint8Array(4 + 4 + bytes.length); // 总长度为：4字节的消息长度 + 4字节的MID + 消息体长度

  // 写入消息长度
  new DataView(totalBuffer.buffer).setUint32(0, msgDataLength, false); // 设置为实际的消息长度，true表示小端序
  // 写入MID
  new DataView(totalBuffer.buffer).setUint32(4, msgId, false); // 设置MID，紧随消息长度之后

  // 写入消息体
  totalBuffer.set(bytes, 8); // 数据体开始于第8个字节
  if (isWebSocket) {
    return totalBuffer.buffer; // 返回二进制流
  }
  return arrayBufferToBase64(totalBuffer.buffer); // 返回base64编码的二进制流
}

function arrayBufferToBase64(buffer) {
  return btoa(String.fromCharCode.apply(null, new Uint8Array(buffer)));
}

export function getResponseData(resDesction) {
  console.log('解析返回数据===>', resDesction);
  
  return function transformResponse(rawResponse) {
    const jsonObject = JSON.parse(rawResponse)
    const buffer = base64ToArrayBuffer(jsonObject.data)
    if (!jsonObject.data) {
      return {
        error: jsonObject.error
      }
    }
    const newBuffer = buffer.slice(8)
    const resp = protoRoot.lookup(resDesction)
    const buf = protobuf.util.newBuffer(newBuffer)
    const decodedResponse = resp.decode(buf)
    if (jsonObject.error > 0 || decodedResponse.error > 0) {
      console.log('数据内部error', decodedResponse.error, resDesction);
      console.log('数据外部error', jsonObject.error, resDesction);
      decodedResponse.error = jsonObject.error > 0 ? jsonObject.error : decodedResponse.error
    }
    console.log('得到解码后的返回值', decodedResponse,decodedResponse.error);
    return decodedResponse; // 返回对象
  }

}

function base64ToArrayBuffer(base64) {
  var binary_string = window.atob(base64);
  var len = binary_string.length;
  var bytes = new Uint8Array(len);

  for (var i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }

  return bytes.buffer;
}

// 处理websocket返回的消息
export function handleWebSocketRes(resDesction, buffer) {
  const newBuffer = buffer.slice(8)
  // const resp = protoRoot.lookup(resDesction)
  // console.group('处理websocket返回的消息---处理');
  // console.log('websocket返回的消息-------------处理', resDesction, typeof resDesction);

  let resp
  if (typeof resDesction === 'string') {
    resp = protoRoot.ProtoMessage[resDesction]
  } else {
    resp = resDesction
  }

  // console.log('处理websocket返回的消息', resp, resDesction, buffer);
  console.groupEnd();
  const buf = protobuf.util.newBuffer(newBuffer)
  const decodedResponse = resp.decode(buf)
  // console.log('得到解码后的返回值', decodedResponse,decodedResponse.error);
  return decodedResponse; // 返回对象
}

// 处理websocket返回的消息
export function getMsgId(newBuffer) {
  return new DataView(newBuffer.slice(4, 8).buffer).getInt32(0, false);
}