<template>
  <div class="msgBox__modal__2kbbd" data-analytics="button" data-label="msg关闭" v-if="openCamera"
    style="display: block;top: -8% !important;">
    <div class="msgBox__modal_HomeAreat_model__2obMO msgBox__modal_CuorsterAreat_model__wwzuA">
      <div class="msgBox__notice_header__kkFdX">
        <span class="msgBox__text__2ODn2" title="">
          拍摄照片</span><span class="msgBox__close_icon__3J9a5" @click="stopCamera"></span>
      </div>
      <div style="display: flex; justify-content: center; align-items: center; width: 100%; padding: 10px 0px;">
        <video ref="video" autoplay style="height: 200px"></video>
      </div>

      <div style="width: 100%; display: flex; justify-content: center; align-items: center; ">
        <button class="msgBox__confirm__3ypnx" style="margin: 5px auto;" @click="takePhoto">拍照</button>
      </div>
      <canvas ref="canvas" style="display: none"></canvas>
      <div
        style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center; padding: 10px 0px;">
        <img :src="photo" alt="Captured Photo" v-if="photo" style="height: 250px;" />
        <button v-if="photo" @click="handleUpLoad" class="msgBox__confirm__3ypnx"
          style="border-radius: unset !important;margin-top: 5px">确定</button>
      </div>

    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: "TakePic",
  props: {
    openCamera: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      photo: null, // 存储拍摄的照片
      stream: null, // 媒体流
      file: null, // 上传的文件
    };
  },
  watch: {
    openCamera(newVal) {
      console.log("openCamera 变化为", newVal);
      if (newVal) {
        this.startCamera();
      } else {
        this.stopCamera();
      }
    }
  },
  methods: {
    async handleUpLoad() {
      const that = this;
      if (!that.file) {
        console.error("没有文件可上传");
        return;
      }
      that.$parent.showLoading(); // 显示加载动画
      const formData = new FormData();
      formData.append('file', that.file); // 添加文件到 FormData
      try {
        const response = await axios.post('https://manage.ms9708.com//gateway/kyc/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log("上传成功:", response.data);
        that.$emit('handleCameraPhoto', response.data);
      } catch (error) {
        console.error("上传失败:", error);
      } finally {
        that.$parent.hideLoading(); // 隐藏加载动画
      }
    },
    stopCamera() {
      console.log("关闭摄像头", this.stream);

      if (this.stream) {
        // 停止所有媒体轨道
        this.stream.getTracks().forEach(track => {
          console.log("停止轨道", track);
          if (track.readyState === 'live') {
            track.stop();
          }
          console.log("轨道状态", track.readyState);
        });

        navigator.mediaDevices.getUserMedia({ video: true }).then(stream => {
          stream.getTracks().forEach(track => track.stop());
        });

        this.stream = null;
        this.$refs.video.pause();
        this.$refs.video.srcObject = null;
        this.$refs.video.load();
        console.log("摄像头已关闭");

        // 可选：检查设备状态
        navigator.mediaDevices.enumerateDevices().then(devices => {
          console.log("设备列表:", devices);
          const cameras = devices.filter(device => device.kind === 'videoinput');
          console.log("摄像头状态:", cameras);
        });
      } else {
        console.warn("没有摄像头流可关闭");
      }

      this.photo = null;
      this.$emit("closeCamera");
    },
    async startCamera() {
      const that = this;
      try {
        console.log("开始打开摄像头");

        // 确保 DOM 完全渲染后再访问 $refs
        await that.$nextTick();

        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
          console.error("浏览器不支持 getUserMedia API 或者需要在 HTTPS 环境下使用");
          return "浏览器不支持 getUserMedia API 或者需要在 HTTPS 环境下使用";
        }

        let stream = await navigator.mediaDevices.getUserMedia({ video: true });

        // 确保 video 元素被正确引用
        if (that.$refs.video) {
          that.$refs.video.srcObject = stream;
          that.stream = stream;
        } else {
          console.error("无法找到 video 元素的引用");
        }

        return null;
      } catch (error) {
        let message = "无法访问摄像头。";

        if (error.name === 'NotAllowedError') {
          message = "用户拒绝访问摄像头。";
          console.error("用户拒绝访问摄像头。");
        } else if (error.name === 'NotFoundError') {
          message = "未找到摄像头设备。";
          console.error("未找到摄像头设备。");
        } else {
          message = "无法访问摄像头: " + error.message;
          console.error("无法访问摄像头: ", error);
        }
        return message;
      }
    },
    takePhoto() {
      const that = this;
      let video = that.$refs.video;
      let canvas = that.$refs.canvas;

      if (!video || !canvas) {
        console.error("无法找到 video 或 canvas 元素的引用");
        return;
      }

      // 设置 canvas 尺寸和视频一致
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      // 将视频内容绘制到 canvas 上
      let context = canvas.getContext("2d");
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      // 获取拍摄的照片（base64 格式）
      that.photo = canvas.toDataURL("image/png");

      // 转换为文件并上传
      that.file = that.dataURLtoFile(that.photo, 'photo.png');
      console.log("拍摄的照片文件:", that.file);
    },
    dataURLtoFile(dataURL, filename) {
      let arr = dataURL.split(',');
      let mime = arr[0].match(/:(.*?);/)[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
  },
  mounted() {
    // if (this.openCamera) {
    //   this.startCamera();
    // }
  },
  unmounted() {
    this.stopCamera();
  },
};
</script>

<style scoped>
video,
img {
  max-width: 100%;
  height: auto;
}

img {
  margin-top: 20px;
}
</style>
