<template>
    <div class="sponsor__wrapper__3Wi1o" style="width: 1350px;">
        <div class="small_banner_swiper">
            <div class="swiper-wrapper">
                <!-- @click="$parent.openPage(`/asdwl?type=${index % 2 + 1} `)" -->
                <div v-for="(item, index) in smallBanner" :key="index" class="sponsor__item__14Sa8 swiper-slide">
                    <img :src="item.fileUrl" width="626px" height="180px" />
                </div>

                <!-- <div class="sponsor__item__14Sa8 swiper-slide" @click="$parent.openPage('/asdwl?type=1')">
                <img src="/static/image/invalid-name@2x.aa69bc6e48a7db32e690d1c67a4ddec1.png" width="626px"
                  height="180px" />
              </div>
              <div class="sponsor__item__14Sa8  swiper-slide" @click="$parent.openPage('/asdwl?type=2')">
                <img src="/static/image/asidunlawei@2x.07eeb5904586eafb13a9b498743f53d9.png" width="626px"
                  height="180px" />
              </div> -->
            </div>
            <div
                class="style__dots__2wknW swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal">
            </div>
        </div>
        <div class="swiper-button-prev" tabindex="0" role="button" aria-label="Previous slide"
            aria-controls="swiper-wrapper-3a5aceb5b3dfd6310"></div>
        <div class="swiper-button-next" tabindex="0" role="button" aria-label="Next slide"
            aria-controls="swiper-wrapper-3a5aceb5b3dfd6310"></div>
    </div>
</template>


<script>
export default {
    name: "smallBanner",
    props: {
        smallBanner: {
            required: true,
            type: Array
        }
    },
    methods: {
        initSwiper() {
            var smallBannerSwiper = new Swiper('.small_banner_swiper', {
            slidesPerView: 2,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });
        }
    },
    mounted() {
        if (this.smallBanner && this.smallBanner.length > 0) {
            this.initSwiper();
        }
    },
    updated() {
        if (this.smallBanner && this.smallBanner.length > 0) {
            this.initSwiper();
        }
    },
    watch: {
        smallBanner() {
            // 每当 bannerList 更新时，重新初始化 Swiper
            if (this.swiper) {
                this.swiper.update(); // 更新 Swiper 以包含新内容
            } else {
                this.initSwiper();
            }
        }
    }
}

</script>

<style scoped>
.small_banner_swiper {
  width: 1350px;
  height: 200px;
  position: relative;
  overflow: hidden;
}
.sponsor__item__14Sa8 {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>