import axios from '@/axios';
import { getResponseData, getRquestData } from '@/utils/commonUtil';

// const BASE_URL = 'http://7nj3j1l.nat.ipyingshe.com';

// const BASE_URL = 'https://5858p27x76.zicp.fun';

const BASE_URL = 'https://finn.ms9708.com';

// 请求未登陆 动态host
export async function notLogin(data) {
    return axios.post(BASE_URL+'/api/login/notLoggedIn',{
        data:  getRquestData('ReqNotLoggedInMessage', data, 'ReqLogin'),
        id: 200006
    }, {
        transformResponse: [getResponseData('ResNotLoggedInMessage')]
    })
}

export async function login(data) {
    return axios.post(BASE_URL+'/api/login/game',{
        data: getRquestData('ReqLoginMessage', data, 'ReqLogin'),
        id: 200002
    }, {
        transformResponse: [getResponseData('ResLoginMessage')]
    })
}

export async function register(data) {
    return axios.post(BASE_URL+'/api/login/register',{
        data: getRquestData('ReqRegisterMessage', data, 'ReqRegister'),
        id: 200001
    }, {
        transformResponse: [getResponseData('ResLoginMessage')]
    })
}

export async function verifyCode(data) {
    return axios.post(BASE_URL+'/api/login/verifyCode',{
        data: getRquestData('ReqMailVerifyCodeMessage', data, 'ReqMailVerifyCode'),
        id: 200004
    }, {
        transformResponse: [getResponseData('ResMailVerifyCodeMessage')]
    })
}
