<template>
  <div class="yx-wid">
    <div class="yx-wid-12 sj-agent">
      <div class="left"></div>
      <div class="center">
        <div class="agent-title">
          <h3>代理合营计划</h3>
          <p>是成为传奇？还是传奇的歌颂者？</p>
        </div>
        <ul class="agent-ul">
          <li>
            <img src="/static/image/pYdM0tlkyHR13bInYm18CFfHPIn9KVxV2AAAA=.webp" />
            <p>精确数据</p>
          </li>
          <li>
            <img
              src="/static/image/2b582jPTDGEDZ9ORjYeKyVPEwP9bA6df7mqTuaMiGSMIW+4xjffjeHFdufQDF3E+Q+JEffYJyuiVceIcXuItFVdIPMZ65fAOKZIYJ+ptEQDZQSEUGS2ebxXCVK3R7p7AE3wNURv54G5nudezSWCEWFzSPnFNQvbfgEtBJYAAAA.webp" />
            <p>专属APP</p>
          </li>
          <li>
            <img src="/static/image/fbEJfB1P7THt8oAAAA==.webp" />
            <p>超高返佣</p>
          </li>
          <li>
            <img src="/static/image/PXRVAAAAAAA=.webp" />
            <p>最优服务</p>
          </li>
        </ul>
        <div class="sj-agent-list">
          <div class="row" v-for="(item, index) in customerServiceList" :key="index">
            <img :src="item.icon" />
            <p>{{ item.name }}</p>
            <div class="link">
              <span v-if="item.links" class="live" @click="openLink(item.links)">咨询</span>
              <span v-if="item.contactDetails" class="copy">复制</span>
            </div>
          </div>

        </div>
        <div class="line"></div>
        <div class="join" @click="$parent.goNav('/applyagent')"></div>
      </div>
      <div class="right"></div>
    </div>
    <div class="yx-wid-12 agent-two">
      <div class="agent-title">
        <h3>财富之路事半功倍</h3>
        <p>4大优势造就行业翘楚</p>
      </div>
      <div class="agent-main">
        <div class="left">
          <div class="row">
            <img src="/static/image/agent11.webp" />
          </div>
          <div class="row">
            <img src="/static/image/agent12.webp" />
          </div>
        </div>
        <div class="center">
          <img src="/static/image/agent15.webp" />
        </div>
        <div class="right">
          <div class="row">
            <img src="/static/image/agent13.webp" />
          </div>
          <div class="row">
            <img src="/static/image/agent14.webp" />
          </div>
        </div>
      </div>
    </div>
    <div class="yx-wid-12 agent-two">
      <div class="agent-title">
        <h3>用户喜爱的娱乐平台</h3>
        <p>聚焦于体育及多元化娱乐</p>
      </div>
      <div class="agent-play">
        <div class="left">
          <img src="/static/image/agent20.webp" />
        </div>
        <div class="right">
          <div class="h4">业内最强产品</div>
          <ul>
            <li>
              <img src="/static/image/agent_play01.webp" />
              <p>更安全</p>
            </li>
            <li>
              <img src="/static/image/agent_play02.webp" />
              <p>更专业</p>
            </li>
            <li>
              <img src="/static/image/agent_play03.webp" />
              <p>更便捷</p>
            </li>
            <li>
              <img src="/static/image/agent_play04.webp" />
              <p>更快速</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "realHuman",
  data() {
    return {
      customerServiceList: null
    };
  },
  created() {
    const that = this;
    that.getConfitData()
  },
  methods: {
    openLink(link) {
      window.open(link, '_blank');
    },
    getConfitData() {
      let that = this;
      that.configData = that.$parent.getConfigDatas();
      console.log('获取到配置信息为', that.configData);
      if (!that.configData || !that.configData.customerServiceList) {
        setTimeout(() => {
          that.getConfitData();
        }, 500);
      } else {
        that.customerServiceList = that.configData.customerServiceList.filter(item => item.showIndex === 2);
      }
    },
  },
  mounted() {
    let that = this;
  },
  updated() {
    let that = this;
  },
  beforeDestroy() { },
};
</script>
<style scoped>
.yx-wid {
  width: 100%;
  background-color: #FFF9ED;
}

.yx-wid-12 {
  width: 1280px;
  margin: 0 auto;
}

.sj-agent {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 80px 0;
}

.sj-agent .right {
  background-image: url(/static/image/agent02.webp) !important;
}

.sj-agent .left,
.sj-agent .right {
  width: 306px;
  height: 686px;
  background-image: url(/static/image/agent01.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  margin-top: 54px;
  flex: none;
}

.sj-agent .center {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
}

.sj-agent .center ul {
  align-items: center;
  display: flex;
  height: 90px;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 45px;
  padding: 0 40px;
}

.sj-agent .center ul li {
  color: #1e1e20;
  font-family: PingFang SC;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
}

.sj-agent .center ul li img {
  width: 54px;
}

.sj-agent .center .line {
  background-image: url(/static/image/agent03.webp);
  background-repeat: no-repeat;
  background-size: contain;
  height: 108px;
  width: 774px;
  margin: 12px -60px 0;
}

.sj-agent .center .join {
  background: url(/static/image/9VBkaeKf0OKFejVGAQWxCQAA.webp);
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 54px;
  color: #fff;
  cursor: pointer;
  font-family: MicrosoftYaHei, serif;
  font-size: 18px;
  font-weight: 500;
  height: 64px;
  line-height: 60px;
  margin: 0 auto;
  text-align: center;
  text-shadow: 0 2px 5px #21212133;
  width: 226px;
}

.agent-title {
  margin-bottom: 20px;
}

.agent-title h3 {
  color: #1d1d1f;
  font-family: PingFang SC;
  font-size: 48px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 48px;
  text-align: center;
}

.agent-title p {
  color: #6e6e73;
  font-family: PingFang SC;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18px;
  margin-top: 20px;
  text-align: center;
}

.sj-agent-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 660px;
  overflow-x: auto;

}

.sj-agent-list .row {
  align-items: center;
  background: linear-gradient(0deg, #fff, #fff),
    linear-gradient(180deg, #fff, #f9f9ff 88.29%, #fff);
  border: 1px solid #fff;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  height: 220px;
  justify-content: center;
  margin-right: 20px;
  overflow: hidden;
  padding: 30px 28px 43px;
  text-align: center;
  width: 200px;
  flex-shrink: 0;
}

::-webkit-scrollbar {
  /* display: none; */
  width: 2px;
  /* Safari 和 Chrome */
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  /* 设置滚动条的颜色 */
  border-radius: 10px;
  /* 设置滚动条的圆角 */
}

* {
  scrollbar-width: thin;
  /* 设置滚动条的宽度为细 */
  scrollbar-color: #888 #f1f1f1;
  /* 设置滚动条的颜色和背景颜色 */
}

/* 适用于 Firefox */



.sj-agent-list .row img {
  width: 60px;
}

.sj-agent-list .row p {
  color: #6e6e73;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-bottom: 20px;
  margin-top: 7px;
}

.sj-agent-list .link {
  display: flex;
  gap: 10px;
}

.sj-agent-list .link .copy,
.sj-agent-list .link .live {
  background: #fff;
  border: 1px solid #6e6e73;
  color: #6e6e73;
  font-size: 14px;
  border-radius: 20px;
  height: 32px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.sj-agent-list .link .live {
  background: linear-gradient(107.59deg, #D75D60, #d6585a 26.65%, #BD171B);
  border: 1px solid transparent;
  color: #fff;
}

.agent-main {
  display: flex;
  align-items: center;
  justify-content: center;
}

.agent-main .left,
.agent-main .right {
  display: flex;
  flex-direction: column;
  width: 290px;
  flex: none;
}

.agent-two {
  padding-top: 90px;
}

.agent-main .left img {
  width: 100%;
}

.agent-main .center {
  flex: 1;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.agent-main .center img,
.agent-main .right img {
  width: 84%;
}

.agent-play {
  display: flex;
  align-items: center;
  gap: 80px;
}

.agent-play .left {
  width: 450px;
  flex: none;
}

.agent-play .left img {
  width: 100%;
}

.agent-play .right {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.agent-play .right .h4 {
  font-size: 20px;
  margin-bottom: 20px;
}

.agent-play .right ul {
  border-radius: 20px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 60px;
}

.agent-play .right ul li img {
  width: 80px;
}

.agent-play .right ul li p {
  text-align: center;
  font-size: 16px;
  color: #6e6e73;
}</style>