import axios from '@/axios'
import { getHallHost, getResponseData, getRquestData } from '@/utils/commonUtil'

export async function getWelfareData(data) {
    const host = getHallHost()
    if (host) {
        return axios.post( 'https://' + host +'/_api/graphql',{
            data: getRquestData('ReqWelfareDataMessage', data, 'ReqWelfareData'),
            id: 400603
        }, {
            transformResponse: [getResponseData('ResWelfareDataMessage')]
        })
    }
}

export async function receiveWelfare(data) {
    const host = getHallHost()
    if (host) {
        return axios.post( 'https://' + host +'/_api/graphql',{
            data: getRquestData('ReqReceiveWelfareMessage', data, 'ReqReceiveWelfare'),
            id: 400605
        }, {
            transformResponse: [getResponseData('ResReceiveWelfareMessage')]
        })
    }
}

// 请求活动列表
export async function getActivityData(data) {
    const host = getHallHost()
    if (host) {
        return axios.post( 'https://' + host +'/_api/graphql',{
            data: getRquestData('ReqActivityDataMessage', data, 'ReqActivityData'),
            id: 400601
        }, {
            transformResponse: [getResponseData('ResActivityDataMessage')]
        })
    }
}

// 请求活动列表
export async function applyActivity(data) {
    const host = getHallHost()
    if (host) {
        return axios.post( 'https://' + host +'/_api/graphql',{
            data: getRquestData('ReqApplyActivityMessage', data, 'ReqApplyActivity'),
            id: 400607
        }, {
            transformResponse: [getResponseData('ResApplyActivityMessage')]
        })
    }
}

// 请求活动申请记录列表
export async function listActivityApplyData(data) {
    const host = getHallHost()
    if (host) {
        return axios.post( 'https://' + host +'/_api/graphql',{
            data: getRquestData('ReqActivityApplyDataMessage', data, 'ReqActivityApplyData'),
            id: 400609
        }, {
            transformResponse: [getResponseData('ResActivityApplyDataMessage')]
        })
    }
}