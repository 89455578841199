import axios from '@/axios'
import { getHallHost, getResponseData, getRquestData } from '@/utils/commonUtil'

//请求邮件数据
export async function getInboxData(data) {
    const host = getHallHost()
    if (host) {
        return axios.post( 'https://' + host +'/_api/graphql',{
            data: getRquestData('ReqInboxDataMessage', data, 'ReqInboxData'),
            id: 400013
        }, {
            transformResponse: [getResponseData('ResInboxDataMessage')]
        })
    }
}

//请求查看邮件
export async function readInBox(data) {
    const host = getHallHost()
    if (host) {
        return axios.post( 'https://' + host +'/_api/graphql',{
            data: getRquestData('ReqReadInBoxMessage', data, 'ReqReadInBox'),
            id: 400015
        }, {
            transformResponse: [getResponseData('ResReadInBoxMessage')]
        })
    }
}

//所有邮件已读
export async function readAllReadInBox(data) {
    const host = getHallHost()
    if (host) {
        return axios.post( 'https://' + host +'/_api/graphql',{
            data: getRquestData('ReqAllReadInBoxMessage', data, 'ReqAllReadInBox'),
            id: 400037
        }, {
            transformResponse: [getResponseData('ResAllReadInBoxMessage')]
        })
    }
}

//请求删除邮件
export async function deleteInbox(data) {
    const host = getHallHost()
    if (host) {
        return axios.post( 'https://' + host +'/_api/graphql',{
            data: getRquestData('ReqDeleteInboxMessage', data, 'ReqDeleteInbox'),
            id: 400017
        }, {
            transformResponse: [getResponseData('ResDeleteInboxMessage')]
        })
    }
}

//请求一键删除已读邮件
export async function deleteReadInbox(data) {
    const host = getHallHost()
    if (host) {
        return axios.post( 'https://' + host +'/_api/graphql',{
            data: getRquestData('ReqOneKeyDeleteReadInboxMessage', data, 'ReqOneKeyDeleteReadInbox'),
            id: 400035
        }, {
            transformResponse: [getResponseData('ResOneKeyDeleteReadInboxMessage')]
        })
    }
}

