<template>
    <div>
        <div class="msgBox__modal__2kbbd" data-analytics="button" data-label="msg关闭" v-if="message" style="display: block">
            <div class="msgBox__modal_HomeAreat_model__2obMO msgBox__modal_CuorsterAreat_model__wwzuA">
                <div class="msgBox__notice_header__kkFdX"><span class="msgBox__text__2ODn2" title="">最新公告</span><span
                        class="msgBox__close_icon__3J9a5" @click="changMessage(null)"></span></div>
                <div class="msgBox__notice_content__3Tozy msgBox__notice_content_height__Z3O1m">
                    <div class="msgBox__flex__-7cOr common__scroll_bar__28TB7" v-for="(item, index) in homenoticelis"
                        :key="index" v-if="index < 2">
                        <p v-html="item"></p>
                    </div>
                    <div class="msgBox__footer__3DHzZ"><button class="msgBox__close__3a3LR" type="button"
                            @click="changMessage(null)">关闭</button><button class="msgBox__confirm__3ypnx" type="button"
                            @click="$parent.goNav('/message/3')">查看全部公告</button></div>
                </div>
            </div>
        </div>
        <div class="notice__led__15X-v">
            <section class="home__led__24I0q">
                <div class="home__notice_box__MTDrm" style="background-color: rgb(238, 243, 247)">
                    <div class="home__notice__2dv3X" style="overflow: visible">
                        <div class="home__title__vakp6"><img
                                src="/static/image/icon-laba.051833f608cedb8253e72e6e93fd6e1c.svg" width="20px"
                                height="24px" /></div>
                        <div class="home__noticelist__1AaLZ home__str_wrap__3K769">
                            <div class="home__notice_wrap__2_0lO" @mouseleave="handleMarqueeLeave"
                                @mouseenter="handleMarqueeEnter" @click="changMessage(1)">
                                <vue-marquee-slider v-if="homenoticelis.length > 0" id="marquee-slider" :speed="calculateSpeed()"
                                    :width="calculateWidth()" :paused="marqueePaused">
                                    <a v-for="(item, index) in homenoticelis" :key="index" v-html="item"></a>
                                </vue-marquee-slider>
                            </div>
                        </div>
                        <p class="fl" style="text-align: center; align-items: center; display: flex;">
                            <a class="home__cuorster__17Pr8" @click="$parent.goNav('/message/3')"
                                style="width: 96px; text-align: right"><button class="notice__more__2MgRR">更多</button></a>
                        </p>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>


<script>
export default {
    name: "smallBanner",
    data() {
        return {
            message: null,
            marqueePaused: false,
        }
    },
    props: {
        homenoticelis: {
            required: true,
            type: Array
        }
    },
    methods: {
        calculateWidth() {
            const longestItem = this.homenoticelis.reduce((a, b) => a.length > b.length ? a : b, '');
            const length = longestItem.length;
            // 根据字符长度设置大约宽度，可调整倍率
            return length * 15; // 10 是字符的平均宽度（可根据实际字体大小调整）
        },
        calculateSpeed() {
            const that = this;
            const length = that.calculateWidth();
            return length * 10;
        },
        changMessage(msg) {
            console.log(123);
            this.message = msg;
        },
        handleMarqueeEnter() {
            const that = this;
            console.log('进入滚动');
            that.marqueePaused = true;
        },
        handleMarqueeLeave() {
            const that = this;
            that.marqueePaused = false;
        },
    },
    watch: {

    }
}

</script>

<style scoped></style>