<template>
    <div class="msgBox__modal__2kbbd" data-analytics="button" data-label="msg关闭" v-if="tanshow" style="display: block">
        <div class="msgBox__modal_HomeAreat_model__2obMO msgBox__modal_CuorsterAreat_model__wwzuA">
            <div class="msgBox__notice_header__kkFdX">
                <span class="msgBox__text__2ODn2" title="">
                    完善您的个人信息</span><span class="msgBox__close_icon__3J9a5" @click="changtanshow"></span>
            </div>
            <div v-if="step == 1" class="msgBox__notice_content__3Tozy msgBox__notice_content_height__Z3O1m"
                style="padding-top: 10px;">

                <div class="userInfo__users_layout_form_item__35aDr">
                    <div class="userInfo__users_layout_form_label__2V7le">真实姓名：</div>
                    <div class="userInfo__users_layout_form_input__2D3z3">
                        <input type="text" data-analytics="button" v-model="tankuangUserName"
                            class="index__Input__tY5CA" style="width: 90%; height: 40px" />
                    </div>
                </div>

                <div class="userInfo__users_layout_form_item__35aDr">
                    <div class="userInfo__users_layout_form_label__2V7le">证件类型：</div>
                    <div class="userInfo__users_layout_form_input__2D3z3">
                        <el-radio fill="#bd171b" v-model="tankuangCidRadio" label="1">身份证</el-radio>
                        <el-radio fill="#bd171b" v-model="tankuangCidRadio" label="2">护照</el-radio>
                    </div>
                </div>

                <div class="userInfo__users_layout_form_item__35aDr">
                    <div class="userInfo__users_layout_form_label__2V7le">证件号码：</div>
                    <div class="userInfo__users_layout_form_input__2D3z3">
                        <input type="text" data-analytics="button" v-model="tankuangCid" class="index__Input__tY5CA"
                            style="width: 90%; height: 40px" />
                    </div>
                </div>

                <div class="msgBox__footer__3DHzZ"><button class="msgBox__confirm__3ypnx" type="button"
                        @click="checkKycStepOne">Next</button></div>
            </div>

            <div class="msgBox__notice_content_height__Z3O1m" style="padding-top: 10px;min-height: 200px;"
                v-if="step == 2">
                <div class="identityContent">
                    <div class="demoContent">

                    </div>
                    <div class="buttonContent">
                        <div class="sc_button_content">
                            <el-upload name="file" action="https://manage.ms9708.com//gateway/kyc/upload"
                                :show-file-list="false" :on-success="handleFaceSuccess"
                                :before-upload="beforeAvatarUpload">
                                <img v-if="faceUrl" :src="faceUrl" class="avatar">
                                <button class="msgBox__confirm__3ypnx"
                                    style="border-radius: unset !important;margin-top: 5px">上传正面</button>
                            </el-upload>

                            <el-upload name="file" action="https://manage.ms9708.com//gateway/kyc/upload"
                                :show-file-list="false" :on-success="handleBackSuccess"
                                :before-upload="beforeAvatarUpload">
                                <img v-if="backUrl" :src="backUrl" class="avatar">
                                <button class="msgBox__confirm__3ypnx"
                                    style="border-radius: unset !important;margin-top: 5px">上传背面</button>
                            </el-upload>
                        </div>
                        <div class="sc_button_content">
                            <button @click="step = 1" class="msgBox__confirm__3ypnx"
                                style="border-radius: unset !important;margin-top: 5px">前一页</button>
                            <button @click="checkKycStepTwo" class="msgBox__confirm__3ypnx"
                                style="border-radius: unset !important;margin-top: 5px">下一页</button>
                        </div>
                    </div>
                </div>


            </div>


            <div class="msgBox__notice_content_height__Z3O1m" style="padding-top: 10px;min-height: 200px;"
                v-if="step == 3">
                <div class="identityContent">
                    <div>
                        允许访问摄像头
                    </div>
                    <div style="width: 90%;border: 1px solid red; padding: 5px 10px; margin: 10px 0;">
                        要继续验证，我们需要访问您设备的相机。
                        请点击左上角窗口中的“允许”按钮，授权浏览器使用您的相机。
                    </div>
                    <div class="buttonContent">
                        <div
                            style="margin: 0 auto; display: flex; flex-direction: column; row-gap: 3px; align-items: center;">
                            <img v-if="cameraPhoto" :src="cameraPhoto" class="avatar">
                            <button @click="handleOepnCamera" class="msgBox__confirm__3ypnx"
                                style="border-radius: unset !important;margin-top: 5px">继续</button>
                            <span>或者</span>
                            <el-upload name="file" action="https://manage.ms9708.com//gateway/kyc/upload"
                                :show-file-list="false" :on-success="handlePhotoSuccess"
                                :before-upload="beforeAvatarUpload">
                                <button class="msgBox__confirm__3ypnx"
                                    style="border-radius: unset !important;margin-top: 5px">上传文件</button>
                            </el-upload>
                        </div>
                        <div class="sc_button_content">
                            <button @click="step = 2" class="msgBox__confirm__3ypnx"
                                style="border-radius: unset !important;margin-top: 5px">前一页</button>
                            <button @click="handleSubmit" class="msgBox__confirm__3ypnx"
                                style="border-radius: unset !important;margin-top: 5px">确定</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <takePic :openCamera="openCamera" ref="child" @closeCamera="closeCamera" @handleCameraPhoto="handleCameraPhoto"></takePic>
    </div>
</template>

<script>
import { applyKycAuth } from '@/service/hall';
import takePic from './takePic.vue';
export default {
    name: "kycInfo",
    components: {
        takePic
    },
    props: {
        tanshow: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            tankuangCid: null,
            tankuangUserName: this.$store.state.userInfo.realName,
            tankuangCidRadio: "1",
            step: 1,
            accpetFileTypes: ['image/jpeg', 'image/pjpeg', 'image/png', 'image/jpg'],
            faceUrl: null,
            backUrl: null,
            cameraPhoto: null,
            front: null,  //正面照片id
            back: null,  //背面照片id
            photo: null,  //照片id
            openCamera: false,
        }
    },
    methods: {
        closeCamera() {
            this.openCamera = false;
        },
        async handleSubmit() {
            const that = this;
            if (!that.front) {
                that.$parent.showTost(0, '请上传正面照片');
                return;
            }
            if (!that.back) {
                that.$parent.showTost(0, '请上传背面照片');
                return;
            }
            if (!that.photo) {
                that.$parent.showTost(0, '请拍摄或上传照片');
                return;
            }
            const res = await applyKycAuth({
                msgID: 400039,
                name: that.tankuangUserName,                   //名字
                documentType: Number(that.tankuangCidRadio),                 //证件类型 1.身份证 2.护照
                IDNumber: that.tankuangCid,                     //证件号码
                front: that.front.toString(),                       //正面
                back: that.back.toString(),             //背面
                photo: that.photo.toString(),                      //照片
            })
            console.log('提交kyc返回值', res);
            
            if (res.error > 0) {
                that.$parent.showTost(0, res.error);
                return
            }
            that.$parent.showTost(1, '提交成功，请等待审核');
            that.$emit("changeShow", false)
        },
        async handleOepnCamera() {
            const errorMessage = await this.$refs.child.startCamera();
            console.log('是否打开了摄像头', open);
            if (errorMessage) {
                this.$parent.showTost(0, errorMessage);
            } else {
                this.openCamera = !this.openCamera;
            }

        },

        checkKycStepTwo() {
            const that = this;
            if (!that.front) {
                that.$parent.showTost(0, '请上传正面照片');
                return;
            }
            if (!that.back) {
                that.$parent.showTost(0, '请上传背面照片');
                return;
            }
            that.step = 3;
        },
        handlePhotoSuccess(res, file) {
            console.log('上传返回值', res, file);
            this.cameraPhoto = URL.createObjectURL(file.raw);
            this.photo = res.data.id;
            this.$parent.hideLoading()
        },
        handleBackSuccess(res, file) {
            console.log('上传返回值', res, file);
            this.backUrl = URL.createObjectURL(file.raw);
            this.back = res.data.id;
            this.$parent.hideLoading()
        },
        handleFaceSuccess(res, file) {
            console.log('上传返回值', res, file);
            this.faceUrl = URL.createObjectURL(file.raw);
            this.front = res.data.id;
            this.$parent.hideLoading()
        },
        changtanshow() {
            this.step = 1;
            this.$emit("changeShow", false)
        },
        checkKycStepOne() {
            let that = this;
            console.log("checkKycStepOne", that.tankuangUserName, that.tankuangCid, that.tankuangCidRadio);

            if (!that.tankuangUserName || !that.tankuangCid || !that.tankuangCidRadio) {
                that.$parent.showTost(0, '请完善信息');
                return;
            }

            that.step = 2;

        },
        beforeAvatarUpload(file) {
            const that = this;
            
            const isJPG = that.accpetFileTypes.includes(file.type);
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.$message.error('上传头像图片只能是 JPG、JPEG、PNG 格式!');
            }
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!');
            }
        
            if(isJPG && isLt2M) {
                that.$parent.showLoading()
            }
            return isJPG && isLt2M;
        },
        handleCameraPhoto(res) {
            const that = this;
            console.log('handleCameraPhoto', res);
            
            that.photo = res.data.id;
            that.cameraPhoto = res.data.url;
            this.openCamera = false;
        },
        showLoading() {
            this.$parent.showLoading()
        },
        hideLoading() {
            this.$parent.hideLoading()
        }
    }
}
</script>

<style lang="less" scoped>
.identityContent {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    min-height: 200px;

    .demoContent {
        display: flex;
        flex-direction: column;
        width: 90%;
        min-height: 180px;
        border: 1px solid red;
    }

    .buttonContent {
        width: 90%;
        margin-top: 10px;
        display: flex;
        flex-direction: column;

        .sc_button_content {
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding: 10px 0;
        }
    }
}

.avatar {
    width: 168px;
    height: 100px;
}
</style>